

.backgroundAdmin {
    display: flex;
    flex: 1;
    flex-direction: row;

    background: url('../../assets/images/background.webp') repeat center center #EFF3FD;
    background-size: cover;
    
    width: 100%;
    min-height: 100vh;
}

