
:root {
  // Primárias - Cores da marca
  --pink-myPups: #e35c82;
  --green-myPups: #c9df5d;
  --yellow-myPups: #f6cc00;
  --blue-myPups: #35d3c0;
  --orange-myPups: #f46a22;


  // Primárias - leves - Mais claras
  --pink-myPups-light: #fa90ae;
  --green-myPups-light: #ddf277;
  --yellow-myPups-light: #f6cc00;
  --blue-myPups-light: #49e2d1;
  --orange-myPups-light: #ff9c66;


  // Primárias - fracas - Muito claras
  --pink-myPups-background: #ffd4e0;
  --green-myPups-background: #f1f7d6;
  --yellow-myPups-background: #fffae1;
  --blue-myPups-background: #dcfffb;
  --orange-myPups-background: #ffe7da;


  // Primárias - sombras
  --pink-myPups-shadow: rgb(227, 92, 130, 0.4);
  --green-myPups-shadow: rgba(201, 223, 93, 0.4);
  --yellow-myPups-shadow: rgb(245, 204, 0, 0.4);
  --blue-myPups-shadow: rgb(53, 211, 192, 0.4);
  --orange-myPups-shadow: rgb(244, 106, 34, 0.4);
}


body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*, *::before, *::after { 
  margin: 0; 
  padding: 0; 
  box-sizing: border-box; 
  -webkit-font-smoothing: antialiased;
}

a { text-decoration: none !important; }

ul { list-style: none; }


.rbc-day-slot .rbc-event--status_payment-in-verification {
  background-color: #fbab18;
}

.rbc-day-slot .rbc-event--status_verified-payment {
  background-color: #df4b4b;
}

.rbc-day-slot .rbc-event--status_order-accepted {
  background-color: #6ebe4a;
}

.rbc-day-slot .rbc-event--status_canceled-by-manager {
  background-color: #0d110b;
}
