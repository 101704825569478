#input-unform {
    position: relative;
}

#input-unform + #input-unform {
    margin-top: 16px;
}

#input-unform label {
    font-size: 16px;
}

/* #input-unform .normal {
    width: 100%;
    height: 50px;
    margin-top: 5px;
    border-radius: 10px;

    background-color: #fff;
    border: 1px solid #ccc;
    
    outline: 0;
    padding: 0 20px;
    font-size: 12px;
} */

#input-unform .has-error {
    width: 100%;
    height: 50px;
    margin-top: 5px;
    border-radius: 10px;

    background-color: #fff;
    border: 1px solid #f65755;
    
    outline: 0;
    padding: 0 20px;
    font-size: 12px;
}

#input-unform .error {
    color: red
}


#input-unform label {
    display: flex;
    color: #8FA7B3;
    margin-bottom: 8px;
    line-height: 24px;
}

#input-unform label span {
    font-size: 14px;
    color: #8FA7B3;
    margin-left: 24px;
    line-height: 24px;
}


#input-unform input,
#input-unform select,
#input-unform textarea {
    width: 100%;
    background: #F5F8FA;
    border: 1px solid #D3E2E5;
    border-radius: 20px;
    outline: none;
    color: #5C8599;
}

#input-unform input {
    height: 64px;
    padding: 0 16px;
}

#input-unform textarea {
    min-height: 120px;
    max-height: 240px;
    resize: vertical;
    padding: 16px;
    line-height: 28px;
}
