.headerAdmin {

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    align-items: flex-end;

    h1 {color: #212121;}
    h6 {color: #8D8D8D;}

    .buttonPopover {
        width: 200px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 6px;

        display: flex;
        align-items: center;
        gap: 14px;
        padding: 0 14px;

        cursor: pointer;

        span {height: 100%; width: 1px; background-color: rgba(0, 0, 0, 0.1);}
    }
}
