#AdminPanel {

    header.headerTop {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        margin: 30px 20px;
        padding: 20px 10px;
        border-radius: 12px;

        background-color: #fa90ae;

        h2 {
            color: #fff;
            font-family: 'Roboto';
        }

        div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        h4 {
            text-align: center;
            line-height: 20px;
        }
        select {
            line-height: 20px;
            border: none;
            background-color: #fa90ae;
            font-size: 25px;
        }
    }

    .rowContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        div:nth-child(1) {margin-left: 0;}
    }

}

