.schedule {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between
}

.scheduleColumn {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.scheduleColumn h6 {
    font-weight: bold;
    margin-bottom: 10px;
}

.scheduleItem {
    font-size: 14px;
    border-bottom: 1px solid #ccc;
    padding: 10px;
    position: relative;
}

.scheduleItem:hover {
    background-color: #ccc;
}

.scheduleItem:hover .xCloseButton {
    visibility: visible;
}

.xCloseButton {
    visibility: hidden;
    position: absolute;
    right: 0;
    top: 0;
}

.scheduleItem small{
    font-size: 12px;
}

button {
    margin-top: 10px;
    border: none;
    background-color: #FFFFFF;
}
