#Table {

	font-family: Arial,
	Helvetica,
	sans-serif;
	border-collapse: collapse;
	width: 100%;

	td,
	th {
		padding: 8px;
	}

	td img {
		max-width: 150px;
	}

	tr:nth-child(odd) {
		background-color: #f2f2f2;
	}

	tr:hover {
		background-color: #ddd
	}

	.hasOnRowClick {
		cursor: pointer
	}

	tr:first-child {
		border: 1px solid #EFF0F4;
		background-color: #F7F8FA;
		border-radius: 18px;
	}

	th {
		padding-top: 12px;
		padding-bottom: 12px;
		text-align: left;
		color: #505470;
	}

}
