
.layoutAdmin {
    display: flex;
    flex: 1;
    flex-direction: column;
  
    align-items: center;

    padding: 30px 10px;
    
    & > main {
        max-width: 1200px;
        width: 94%;
    }

    .centerContentMain {
        display: flex;
        flex: 1;
        flex-direction: column;
    
        align-items: center;
    }
}

