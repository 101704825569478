#ChatWindowStyle {

    main {
        padding: 10px;
        height: 370px;
        // margin: 10vh 0 10vh;
        // padding-bottom: 40vh;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
    }

    main::-webkit-scrollbar {
        width: 0.5rem;
    }

    main::-webkit-scrollbar-track {
        background: #1e1e24;
    }

    main::-webkit-scrollbar-thumb {
        background: var(--blue-myPups);
    }


    p {
        max-width: 270px;
        min-width: 70px;
        margin-bottom: 12px;
        line-height: 24px;
        padding: 10px 20px;
        border-radius: 25px;
        position: relative;
        color: white;
    }

    div {
        position: relative;
        h6 {
            position: absolute;
            color: white;
            text-align: right;
            bottom: 0;
            font-size: 10px;
            z-index: 3;
        }
    }

    .message {
        display: flex;
        align-items: center;
    }

    .sent {
        flex-direction: row-reverse;
    }

    .sent p {
        color: white;
        background: #0b93f6;
        align-self: flex-end;
        text-align: right;
        padding-left: 10px;
    }
    .received p {
        background: #e5e5ea;
        color: black;
        text-align: left;
        padding-right: 10px;
    }

    .sent h6 {
        right: 0;
        transform: translateY(-40%) translateX(-65%);
    }
    .received h6 {
        left: 0;
        transform: translateY(-40%) translateX(65%);
    }

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 2px 5px;
    }
}

#chatWindowFooter {
    margin: 10px 0;
    padding: 0 20px;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    align-items: center;

    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    border-top-width: 1px solid #D3E2E5;

    input {
        border: 1px solid #fff;
        padding: 10px;
        width: 87%;
        border-radius: 15px;
    }

    input:focus {
        outline: none;
    }

    button {
        border: none;
        font-size: 22.5px;
        color: lightgreen;
        cursor: pointer;
        width: 13%;
        background-color: transparent;
        margin: 0;
    }
}
