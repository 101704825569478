

#CardContainer {
    padding: 25px;
    background-color: #FFF;
    width: 100%;
    border-radius: 12px;

    &.small {
		& {
			max-width: 600px;
		}
	}

    &.medium {
		& {
			max-width: 900px;
		}
	}

	&.large {
		& {
			max-width: 1200px;
		}
	}

}

