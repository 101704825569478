#PupCard {
    width: 180px;
    height: 80px;

    border: 1px solid #D3E2E5;
    border-radius: 8px;
    margin: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    p {
        margin: 0;
        text-align: center;
        padding: 0 30px;
    }

    .eyeIcon {
        position: absolute;
        top: 33px;
        right: 10px;
    }

    .editIcon {
        position: absolute;
        top: 10px;
        right: 10px;
        visibility: hidden;
    }

    &:hover {
        background-color: #ecf1f2;
        cursor: pointer;

        .editIcon {
            visibility: visible;
        }
    }
}
