#financialTable {

    padding: 15px;
    margin: 30px 20px;
    border: 1px solid #D3E2E5;
    border-radius: 12px;
    
    min-width: 955px;
    max-width: 1200px;
    width: 96%;
    
    .tableHeader {
        font-weight: bold;
        border-bottom: none;
        background-color: #D3E2E5;
        padding: 15px 0px;
    }

    .row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        position: relative;

        padding: 15px 10px;
        border-bottom: 1px solid rgba(224, 224, 224, 1);

        p {
            width: 110px;
            margin: 3px 10px;
            text-align: left;
        }
        p:first-child {
            width: 120px;
        }
        p:nth-child(2) {
            width: 180px;
        }
        p:nth-child(3) {
            width: 120px;
        }
        p:nth-child(4) {
            width: 120px;
        }
        p:nth-last-child(2) {
            width: 100px;
        }
        p:last-child {
            width: 60px;
        }
    }

    .redCircle {
        width: 8px;
        height: 8px;
        display: flex;
        position: relative;
        border-radius: 20px;
        left: -15px;
        top: 18px;
        background-color: red;
    }
}
