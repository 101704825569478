
#login {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: calc(max(100vh, 800px));
    background-color: var(--blue-myPups);
}

#login main {
    display: flex;
    flex: 1;
    background-color: var(--blue-myPups);
}


#login main .form-auth {
    width: 90%;
    max-width: 700px;
    margin: 32px auto;
  
    background: #FFFFFF;
    border: 1px solid #D3E2E5;
    border-radius: 20px;

    padding: 64px 7%;
    overflow: hidden;

    .input-block label {
        display: flex;
        color: #8FA7B3;
        margin-bottom: 8px;
        line-height: 24px;
    }
    
    .input-block label span {
        font-size: 14px;
        color: #8FA7B3;
        margin-left: 24px;
        line-height: 24px;
    }
    
    .input-block input,
    .input-block select,
    .input-block textarea {
        width: 100%;
        background: #F5F8FA;
        border: 1px solid #D3E2E5;
        border-radius: 20px;
        outline: none;
        color: #5C8599;
    }
    
    .input-block input {
        height: 64px;
        padding: 0 16px;
    }
    
    button.buttonAuth {
        margin-top: 64px;
      
        width: 100%;
        height: 64px;
        border: 0;
        cursor: pointer;
        background: var(--green-myPups);
        border-radius: 20px;
        color: #FFFFFF;
        font-weight: 800;
      
        display: flex;
        justify-content: center;
        align-items: center;
      
        transition: background-color 0.2s;
    }
    
    button.buttonAuth:hover {
        background: #b2c748;
    }

}

