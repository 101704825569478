/********************
preloader
********************/
$color-black: #000000;
$color-para-dark: #666b6d;

/********************
section
********************/
$section-padding: 12rem;
$section-padding-responsive: 10rem;

/********************
button
********************/
$button-color-blue: #c7dffa;
$button-color-orange: #ffd588;

:root {
    --transform-fix: 'none';
}
