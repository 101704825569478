#pupScreen {
    
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 30px;
    gap: 30px;

    .animalClassesContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 10px;
    }
     
    .dividerAnimalsSpecies {
        display: flex;
        flex-direction: row;
        align-items: center;

        h3 {
            margin: 0 8px 6px 0;    
        }
    }
}
