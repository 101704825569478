#page-create-store {
	display: flex;
}

#page-create-store main {
    flex: 1;
}


form.create-store-form .leaflet-container {
    margin-bottom: 40px;
    border: 1px solid #D3E2E5;
    border-radius: 20px;
    width: 100%;
    height: 350px;
}


form.create-store-form {
    width: 700px;
    margin: 64px auto;
  
    background: #FFFFFF;
    border: 1px solid #D3E2E5;
    border-radius: 20px;
  
    padding: 64px 80px;
  
    overflow: hidden;
}

form.create-store-form fieldset {
    border: 0;
}

form.create-store-form fieldset + fieldset {
    margin-top: 80px;
}

form.create-store-form fieldset legend {
    width: 100%;

    font-size: 32px;
    line-height: 34px;
    color: #5C8599;
    font-weight: 700;
  
    border-bottom: 1px solid #D3E2E5;
    margin-bottom: 40px;
    padding-bottom: 24px;
}

form.create-store-form fieldset legend span {
    font-size: 16px;
    line-height: 16px;
}

form.create-store-form .input-block + .input-block {
    margin-top: 24px;
}

form.create-store-form .input-block label {
    display: flex;
    color: #8FA7B3;
    margin-bottom: 8px;
    line-height: 24px;
}

form.create-store-form .input-block label span {
    font-size: 14px;
    color: #8FA7B3;
    margin-left: 24px;
    line-height: 24px;
}


form.create-store-form .input-block input,
form.create-store-form .input-block select,
form.create-store-form .input-block textarea {
    width: 100%;
    background: #F5F8FA;
    border: 1px solid #D3E2E5;
    border-radius: 20px;
    outline: none;
    color: #5C8599;
}

form.create-store-form .input-block input {
    height: 64px;
    padding: 0 16px;
}

form.create-store-form .input-block select {
    height: 64px;
    padding: 0 16px;
}

form.create-store-form .input-block textarea {
    min-height: 120px;
    max-height: 240px;
    resize: vertical;
    padding: 16px;
    line-height: 28px;
}

form.create-store-form .input-block select {
    height: 64px;
    padding: 16px;
    line-height: 30px;
}

form.create-store-form .input-block .images-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 16px;
}

form.create-store-form .input-block .images-container img {
    width: 100%;
    height: 96px;
    object-fit: cover;
    border-radius: 20px;
}

form.create-store-form .input-block .new-image {
	height: 96px;
	background: #F5F8FA;
	border: 1px dashed #96D2F0;
	border-radius: 20px;
	cursor: pointer;

	display: flex;
	justify-content: center;
	align-items: center;
}

form.create-store-form .input-block input[type="file"] {
    display: none;
}

form.create-store-form .input-block .button-select {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

form.create-store-form .input-block .button-select button {
    height: 64px;
    background: #F5F8FA;
    border: 1px solid #D3E2E5;
    color: #5C8599;
    cursor: pointer;
}

form.create-store-form .input-block .button-select button.active {
    background: #EDFFF6;
    border: 1px solid #A1E9C5;
    color: #37C77F;
}

form.create-store-form .input-block .button-select button:first-child {
    border-radius: 20px 0px 0px 20px;
}

form.create-store-form .input-block .button-select button:last-child {
    border-radius: 0 20px 20px 0;
    border-left: 0;
}

form.create-store-form button.confirm-button {
    margin-top: 64px;
  
    width: 100%;
    height: 64px;
    border: 0;
    cursor: pointer;
    background: #3CDC8C;
    border-radius: 20px;
    color: #FFFFFF;
    font-weight: 800;
  
    display: flex;
    justify-content: center;
    align-items: center;
  
    transition: background-color 0.2s;
}

form.create-store-form button.confirm-button svg {
    margin-right: 16px;
}

form.create-store-form button.confirm-button:hover {
    background: #36CF82;
}


.items-grid {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.items-grid li {
    background: #f5f5f5;
    border: 2px solid #f5f5f5;
    height: 180px;
    border-radius: 8px;
    padding: 32px 24px 16px;
	margin: 1px;
    
	width: 170px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  
    text-align: center;
  
    cursor: pointer;
}

.items-grid li span {
    flex: 1;
    margin-top: 12px;

    display: flex;
    align-items: center;
}

.items-grid li.selected {
    background: #E1FAEC;
    border: 2px solid #34CB79;
}

.items-grid li img {
    width: 100%;
    height: 90px;
    object-fit: cover;
    border-radius: 20px;
}

.button-verified-email {
    margin-top: 24px;
  
    width: 50%;
    height: 64px;
    border: 0;
    cursor: pointer;
    background: #3CDC8C;
    border-radius: 20px;
    color: #FFFFFF;
    font-weight: 800;
    font-size: 18px;

    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;

    transition: background-color 0.2s;
}

.button-verified-email:hover {
    background: #36CF82;
}


.button-terms {
    width: 160px;
    height: 40px;
    border: 0;
    cursor: pointer;
    background: #3CDC8C;
    border-radius: 16px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;

    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;

    transition: background-color 0.2s;
}

.button-terms:hover {
    background: #36CF82;
}

.button-terms.negative {
    background: #a1a7a4;
}

.button-terms.negative:hover {
    background: #aaaeac;
}
