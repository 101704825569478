#ChatCard {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-right: 3%;

    background-color: #eee;
    border-radius: 20px;
    cursor: pointer;

    p {
        margin: 0;
        padding: 0 6px;
    }

}

#chatContainer {
    width: 400px;
    position: absolute;
    bottom: 0;
    right: 3%;

    .chat-box {
        width: 100%;
        height: 500px;
        border-radius: 25px;
        background-color: #eee;
    }
    
    .chat-box .chatHeader {
        background-color: #3fda73;
        display: flex;
        padding: 15px;
        border-radius: 20px 20px 0 0;
        color: #fff;
        font-size: 16px;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }
}


.iconButton {
    background-color: rgba(255, 255, 255, 0.344);
    padding: 4px 6px;
}

.iconButton:hover {
    background-color: rgba(208, 208, 208, 0.391);
}
