
#home {
    width: 100vw;
    height: 100vh;
    background-image: url("https://wallpaperaccess.com/full/5531093.jpg");
    background-size: cover;

    header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0px 10px 0px 10px;
    }

    main {
        display: flex;
        justify-content: center;

        img {
            position: absolute;
            width: 250px;
            top: 40px;
        }
    }
}
