#terms {
    display: flex;
    flex-direction: column;
}

#terms header {
    display: flex;
    flex-direction: row;
}

#terms header, #terms main {
    margin: 20px auto;
    width: 900px;
}

#terms header img {
    position: relative;
    width: 220px;
    top: 0;
    right: 10px;
}
