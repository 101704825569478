
#sidebar-navigator  {
    display: flex;
    flex: 1;
    height: auto; 
    background-color: #e35c82;
    max-width: 225px;
    
    .nav-menu {
        width: 225px;
        display: flex;
        justify-content: center;
        max-height: 95vh;
        transition: 350ms;
    }
    
    .nav-menu-items {
        position: fixed;
        height: 100%;
        width: 225px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    span {
        margin-left: 16px;
    }

    header {
        height: 80px;
        width: 100%;
        font-size: 20px;
        text-align: center;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #f5f5f5;
        
        img {
            width: 100%;
        }
    }
    
    .nav-text {
        display: flex;
        justify-content: start;
        align-items: center;
        padding: 8px 10px 8px 8px;
        list-style: none;
        height: 60px;
    }
    
    .nav-text a {
        text-decoration: none;
        color: #f5f5f5;
        font-size: 16px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 12px;
        border-radius: 4px;
        background-color: rgb(226, 140, 140);
    }
    .nav-text a:hover {
        background-color: #f6cc00;
    }
    .nav-text a.selected {
        background-color: #35d3c0;
    }
    
    .logout-button {
        text-decoration: none;
        color: #f5f5f5;
        font-size: 16px;
        width: 95%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border-radius: 4px;
        margin-bottom: 30px;
        background-color: rgb(226, 140, 140);
    }
    .logout-button:hover {
        cursor: pointer;
        background-color: #e42020;
    }
    

}

