
.searchInputContainer {
    height: 30px;
    display: flex;
    align-items: center;

    border-radius: 20px;
    background-color: #EFF0F4;
    position: relative;

    max-width: 250px;

    .searchInputIconLabel {
        position: absolute;
        left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: text;
    }

    .searchInputIcon {
        position: absolute;
    }

    .searchInput {
        height: 30px;
        border: none;
        border-radius: 20px;
        padding: 0 0 0 40px;
        background-color: #EFF0F4;
    }

    .searchInput:before {
        content: "\f00d";
        display: block;
        z-index: 50;
        font-family: 'FontAwesome';
        font-weight: 100;
        font-size: 14px;
        background-color: #ccc;
        padding: 3px 4px;
        border-radius: 20px;
        cursor: pointer;
        color: #fff;
    }

}

