/********************
fonts
********************/
// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

// /********************
// general
// ********************/
// *, *::before, *::after{ margin: 0; padding: 0; box-sizing: inherit; -webkit-font-smoothing: antialiased; }
// html{ font-size: 62.5%; overflow-x: hidden !important; letter-spacing: -.2px; }
// body{ font-family: 'Poppins', sans-serif; box-sizing: border-box; overflow-x: hidden !important;}
// a{ text-decoration: none !important; }
// ul{ list-style: none; }

/********************
text styles
********************/
// .section-heading{ font-weight: 600; font-size: 6rem; width: 68%; line-height: -4rem;
//     @media ( max-width: 991.98px ){ font-size: 5rem; text-align: center; margin: 0 auto 5rem auto; width: 85%; }
//     @media (max-width: 575.98px){ font-size: 4rem; width: 90%; }
// }
// .color-black{ color: $color-black; }
// .paragraph{ font-size: 1.6rem; line-height: 3rem; 
//     &.dark{ color: $color-para-dark; }
//     &.white{ color: white; }
// }

/********************
bootstrap
********************/
.container{ padding: 0; }

.disp-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}


/********************
font awesome
********************/
.fad.fa-lightbulb-on, .fad.fa-search, .fad.fa-angle-double-down{
  &::before{ opacity: var(--fa-primary-opacity, .5); }
  &::after{ opacity: var(--fa-secondary-opacity, 1); }
}

/********************
button
********************/
.button{ width: 27.1rem; height: 6.5rem; display: inline-flex; justify-content: center; align-items: center; 
    background-color: transparent; border: 4px solid $button-color-blue; border-radius: 10rem;
    font-size: 1.6rem; font-weight: 600; color: #666b6d; @include animateIt;
    letter-spacing: .1rem;
    i{ margin-left: 1rem; @include animateIt; }
    &:focus, &:hover{ background-color: $button-color-blue; color: white; outline: 0; i{ color: white; } }
    &__wrapper{ margin: 0 auto; .button:nth-child(n+2){ margin-left: 1.8rem; } }
    @media (max-width: 650px) {
        display: flex;
        margin: 0 auto;
        &__wrapper{ width: 100%; .button:nth-child(n+2){ margin: 1rem auto 0 auto; } }
    }
}

/********************
Icons
********************/
.icon-b {
    height: 9rem;
    width: 9rem;
}
.icon-b-small {
    height: 6rem;
    width: 6rem;
}

/********************
social
********************/
.social{ 
  ul{
      li{ display: inline-block; width: 4.8rem; height: 4.8rem; border-radius: 50%;
        &:nth-child(n+2){ margin-left: .4rem; }
        &.facebook{ background: rgb(147,65,212);
          background: linear-gradient(0deg, rgba(147,65,212,1) 0%, rgba(217,142,251,1) 50% , rgba(147,65,212,1) 100%); 
          filter: drop-shadow(0px 5px 5px rgba(147,65,212,.4));
          -webkit-filter: drop-shadow(0px 5px 5px rgba(147,65,212,.4));
          -moz-filter: drop-shadow(0px 5px 5px rgba(147,65,212,.4));
          transform: var(--transform-fix); backface-visibility: hidden; perspective: 1000;
        }
        &.twitter{ background: rgb(255,177,107);
          background: linear-gradient(0deg, rgba(255,177,107,1) 0%, rgba(255,219,141,1) 50%, rgba(255,177,107,1) 100%);
          filter: drop-shadow(0px 5px 5px rgba(212,154,93,.4));
          -webkit-filter: drop-shadow(0px 5px 5px rgba(212,154,93,.4));
          -moz-filter: drop-shadow(0px 5px 5px rgba(212,154,93,.4));
          transform: var(--transform-fix); backface-visibility: hidden; perspective: 1000;
        }
        &.linkedin{ background: rgb(25,165,161);
          background: linear-gradient(0deg, rgba(25,165,161,1) 0%, rgba(99,232,228,1) 50%, rgba(25,165,161,1) 100%); 
          filter: drop-shadow(0px 5px 5px rgba(108,222,220,.4));
          -webkit-filter: drop-shadow(0px 5px 5px rgba(108,222,220,.4));
          -moz-filter: drop-shadow(0px 5px 5px rgba(108,222,220,.4));
          transform: var(--transform-fix); backface-visibility: hidden; perspective: 1000;
        }
        &.youtube{ background: rgb(242,2,107);
          background: linear-gradient(0deg, rgba(242,2,107,1) 0%, rgba(255,138,175,1) 50%, rgba(242,2,107,1) 100%); 
          filter: drop-shadow(0px 5px 5px rgba(245,69,144,.4));
          -webkit-filter: drop-shadow(0px 5px 5px rgba(245,69,144,.4));
          -moz-filter: drop-shadow(0px 5px 5px rgba(245,69,144,.4));
          transform: var(--transform-fix); backface-visibility: hidden; perspective: 1000;
        }
        a{ width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;
          font-size: 2rem; color: white;
        }
        background-size: 200% 200% !important; background-position: center bottom !important; @include animateIt;
        &:hover{
          background-position: center top !important;
        }
      }
    }
 }

/********************
download buttons
********************/
.download-buttons{
    a{ display: flex; justify-content: center; align-items: center; width: 24.4rem; height: 8.1rem; background-color: grey;
        border-radius: 5rem; @include animateIt;
        i{ font-size: 3.4rem; color: white; }
        .button-content{ margin-left: 1.3rem; letter-spacing: .1rem;
            h6{ font-size: 1.2rem; color: #ffd4e3; }
            span{ display: block; font-size: 2rem; color: white; margin-top: .2rem; letter-spacing: initial; }
        }
        &:last-child{ .button-content{ h6{ color: #bbdef9; } } }
        &.google-play{ background: rgb(242,2,107);
          background: linear-gradient(0deg, rgba(242,2,107,1) 0%, rgba(255,138,175,1) 50%, rgba(242,2,107,1) 100%);
          filter: drop-shadow(0px 10px 10px rgba(245,69,144,.4));
          -webkit-filter: drop-shadow(0px 10px 10px rgba(245,69,144,.4));
          -moz-filter: drop-shadow(0px 10px 10px rgba(245,69,144,.4));
          transform: var(--transform-fix); backface-visibility: hidden; perspective: 1000;
        }
        &.apple-store{background: rgb(19,105,236);
          background: linear-gradient(0deg, rgba(19,105,236,1) 0%, rgba(101,190,244,1) 50%, rgba(19,105,236,1) 100%);
          filter: drop-shadow(0px 10px 10px rgba(33,120,237,.4));
          -webkit-filter: drop-shadow(0px 10px 10px rgba(33,120,237,.4));
          -moz-filter: drop-shadow(0px 10px 10px rgba(33,120,237,.4));
          transform: var(--transform-fix); backface-visibility: hidden; perspective: 1000;
        }
        background-size: 200% 200% !important; background-position: center bottom !important;
        &:hover{
          background-position: center top !important;
        }
    }
}

