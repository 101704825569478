#page-finish-purchase {
	display: flex;
}

#page-finish-purchase main {
    flex: 1;
    background-color: #36CF82;
}


#page-finish-purchase main form.finish-purchase-form {
    width: 90%;
    max-width: 700px;
    margin: 64px auto;
  
    background: #FFFFFF;
    border: 1px solid #D3E2E5;
    border-radius: 20px;

    padding: 64px 7%;
    overflow: hidden;

    fieldset {
        border: 0;
    }
    
    fieldset legend {
        width: 100%;
    
        font-size: 32px;
        line-height: 34px;
        color: #5C8599;
        font-weight: 700;
      
        border-bottom: 1px solid #D3E2E5;
        margin-bottom: 40px;
        padding-bottom: 24px;
    }

    fieldset legend span {
        font-size: 16px;
        line-height: 16px;
    }

    .input-block + .input-block {
        margin-top: 24px;
    }

    .row-input-block {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .row-input-block > .input-block {
        margin-top: 24px;

        input {
            width: 85%;
        }
    }

    .input-block label {
        display: flex;
        color: #8FA7B3;
        margin-bottom: 8px;
        line-height: 24px;
    }
    
    .input-block label span {
        font-size: 14px;
        color: #8FA7B3;
        margin-left: 24px;
        line-height: 24px;
    }
    
    
    .input-block input,
    .input-block select,
    .input-block textarea {
        width: 100%;
        background: #F5F8FA;
        border: 1px solid #D3E2E5;
        border-radius: 20px;
        outline: none;
        color: #0f5172;
    }
    
    .input-block input {
        height: 64px;
        padding: 0 16px;
    }
    
    .input-block textarea {
        min-height: 120px;
        max-height: 240px;
        resize: vertical;
        padding: 16px;
        line-height: 28px;
    }
    
    .input-block select {
        height: 64px;
        padding: 16px;
        line-height: 30px;
    }
    
    .input-block .button-select {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    
    button.confirm-button {
        margin-top: 64px;
      
        width: 100%;
        height: 64px;
        border: 0;
        cursor: pointer;
        background: #3CDC8C;
        border-radius: 20px;
        color: #FFFFFF;
        font-weight: 800;
      
        display: flex;
        justify-content: center;
        align-items: center;
      
        transition: background-color 0.2s;
    }
    
    button.confirm-button:hover {
        background: #36CF82;
    }

    button.back-button {
        margin-top: 24px;
      
        width: 100%;
        height: 64px;
        border: 0;
        cursor: pointer;
        background: #ccc;
        border-radius: 20px;
        color: #FFFFFF;
        font-weight: 800;
      
        display: flex;
        justify-content: center;
        align-items: center;
      
        transition: background-color 0.2s;
    }
    
}

