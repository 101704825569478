
#metricCard {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 260px;
    height: 120px;

    box-sizing: border-box;
    border-radius: 20px;
    margin: 15px;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);

    h5 {
        font-size: 22px;
        padding-left: 20px;
        margin-top: 10px;
    }
    p {
        font-size: 20px;
        text-align: right;
        padding-right: 30px;
        font-weight: bold;
        position: relative;
        bottom: -5px;
    }
}

.positive {
    background: linear-gradient(149.97deg, #FFF 8.13%, #cbf8c5 92.67%);
    border: 1px solid #45ed2f;
}

.neutral {
    background: linear-gradient(149.97deg, #FFF 8.13%, #fbf9cd 92.67%);
    border: 1px solid #eee870;
}

.negative {
    background: linear-gradient(149.97deg, #FFF 8.13%, #fbcdcd 92.67%);
    border: 1px solid #d87676;
}
